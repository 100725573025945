import React, { ChangeEvent, FunctionComponent, ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as styles from '../styles/earlyaccess.module.scss';

interface RequestBody {
    email: string;
    firstName: string;
    lastName?: string;
    phoneNumber: string;
    sendCopy: boolean;
    systemUtilized: string;
};

const EarlyAccessPage: FunctionComponent = (): ReactElement => {
    const [email, setEmail] = useState('');
    const [lawFirm, setLawFirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [systemUtilized, setSystemUtilized] = useState('');
    const [sendCopy, setSendCopy] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [otherChosen, setOtherChosen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Kick of POST to add a new contact and deal to PCHGo's Bitrix instance
     * @param event Form submission event
     */
    const bitrixRequest = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        try {
            setIsLoading(true);
            const response: Response = await fetch('https://cotua-apis.azurewebsites.net/api/PCHGoBitrix', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email,
                    lawFirm,
                    firstName,
                    lastName,
                    phoneNumber: parsePhoneNumberFromString(phoneNumber, 'US')?.format('E.164'),
                    systemUtilized,
                    sendCopy,
                }),
            });

            if (response.status === 200) {
                setIsSuccess(true);
                setSuccessMessage('Thank you for your interest in early access!');
            } else {
                setIsError(true);
                setErrorMessage('We apologize but something went wrong. Try again later');
            }

            setIsLoading(false);
        } catch(bitrixRequestExceptionError) {
            console.error({ bitrixRequestExceptionError });
            setIsLoading(false);
            setErrorMessage('We apologize but something went wrong. Try again later');
            setIsError(true);
            throw new Error(bitrixRequestExceptionError);
        }
    };

    return (
        <main>
            <section className={styles.earlyAccess}>
                <h1 className={styles.earlyAccessTitle}>PCH - Case Clinical Onboarding</h1>
                <p className={styles.earlyAccessDescription}>Sign up to be added to the PCH Onboarding Queue</p>
                <p className={styles.required}>* Required</p>
                <form className={styles.earlyAccessForm} onSubmit={bitrixRequest}>
                    <div className={styles.fieldWrapper}>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="email"
                        >Email <span className={styles.asterisk}>*</span></label>
                        <input
                            className={styles.field}
                            required
                            type="email"
                            id="email"
                            name="email"
                            onChange={($event: ChangeEvent<HTMLInputElement>) => setEmail($event.target.value)}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="law-firm"
                        >Law Firm <span className={styles.asterisk}>*</span></label>
                        <input
                            className={styles.field}
                            required
                            type="text"
                            id="law-firm"
                            name="law-firm"
                            onChange={($event: ChangeEvent<HTMLInputElement>) => setLawFirm($event.target.value)}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="first-name"
                        >First Name <span className={styles.asterisk}>*</span></label>
                        <input
                            className={styles.field}
                            required
                            type="text"
                            id="first-name"
                            name="first-name"
                            onChange={($event: ChangeEvent<HTMLInputElement>) => setFirstName($event.target.value)}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="last-name"
                        >Last Name</label>
                        <input
                            className={styles.field}
                            type="text"
                            id="last-name"
                            name="last-name"
                            onChange={($event: ChangeEvent<HTMLInputElement>) => setLastName($event.target.value)}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="phone-number"
                        >Phone Number <span className={styles.asterisk}>*</span></label>
                        <small>Format: 123-456-7890</small>
                        <input
                            className={styles.field}
                            required
                            type="tel"
                            placeholder="123-456-7890"
                            id="phone-number"
                            name="phone-number"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            onChange={($event: ChangeEvent<HTMLInputElement>) => setPhoneNumber($event.target.value)}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <strong>System Utilized <span className={styles.asterisk}>*</span></strong>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="filevine"
                        >
                            <input
                                className={styles.field}
                                required
                                type="radio"
                                id="filevine"
                                name="system-utilized"
                                value="Filevine"
                                onChange={($event: ChangeEvent<HTMLInputElement>) => {
                                    setOtherChosen(false);
                                    setSystemUtilized('Filevine');
                                }}
                            /> Filevine
                        </label>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="casepeer"
                        >
                            <input
                                className={styles.field}
                                required
                                type="radio"
                                id="casepeer"
                                name="system-utilized"
                                value="Casepeer"
                                onChange={($event: ChangeEvent<HTMLInputElement>) => {
                                    setOtherChosen(false);
                                    setSystemUtilized('Casepeer');
                                }}
                            /> Casepeer
                        </label>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="litify"
                        >
                            <input
                                className={styles.field}
                                required
                                type="radio"
                                id="litify"
                                name="system-utilized"
                                value="Litify"
                                onChange={($event: ChangeEvent<HTMLInputElement>) => {
                                    setOtherChosen(false);
                                    setSystemUtilized('Litify');
                                }}
                            /> Litify
                        </label>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="paper-files"
                        >
                            <input
                                className={styles.field}
                                required
                                type="radio"
                                id="paper-files"
                                name="system-utilized"
                                value="Paper Files"
                                onChange={($event: ChangeEvent<HTMLInputElement>) => {
                                    setOtherChosen(false);
                                    setSystemUtilized('Paper Files');
                                }}
                            /> Paper Files
                        </label>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="other"
                        >
                            <input
                                className={styles.field}
                                required
                                type="radio"
                                id="other"
                                name="system-utilized"
                                value="Other"
                                onChange={() => {
                                    // setOtherChosen(true);
                                    // document.getElementById('other')?.focus();
                                    setSystemUtilized('Other');
                                }}
                            /> Other
                            {/* <input
                                className={styles.field}
                                required
                                type="radio"
                                id="other"
                                name="system-utilized"
                                value="Other"
                                onChange={() => {
                                    setOtherChosen(true);
                                    document.getElementById('other')?.focus();
                                }}
                            /> Other: <input
                                disabled={otherChosen ? false : true}
                                className={styles.field}
                                type="text"
                                id="other"
                                onChange={($event: ChangeEvent<HTMLInputElement>) => setSystemUtilized($event.target.value)}
                            /> */}
                        </label>
                    </div>
                    <div className={styles.fieldWrapper}>
                        <label
                            className={styles.fieldLabel}
                            htmlFor="send-copy"
                        >
                            <input
                                className={styles.field}
                                type="checkbox"
                                id="send-copy"
                                name="send-copy"
                                onChange={($event: ChangeEvent<HTMLInputElement>) => setSendCopy($event.target.checked)}
                            /> Send me a copy of my responses
                        </label>
                    </div>
                    {isError &&
                        <small>{errorMessage}</small>
                    }
                    {isSuccess &&
                        <small>{successMessage}</small>
                    }
                    <button
                        className={styles.submit}
                        type="submit"
                    >
                        {isLoading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Submit'}
                    </button>
                </form>
            </section>
        </main>
    );
}

export default EarlyAccessPage;
